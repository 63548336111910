import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { CenterSpinner } from "@vendor-app/app/AdminRoute/components/elements/CenterSpinner";

import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { useSmartrrVendorSelector, useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { syncAccountPlans } from "@vendor-app/app/_state/actionCreators/accountPlans";

export const Route = createFileRoute("/admin/")({
  component: Index,
});

function Index() {
  const { activePlan } = useSmartrrVendorSelector(state => state.accountPlans);
  const isSuperUser = useIsSuperUserSelector();
  const dispatch = useSmartrrVendorDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAccountPlans = async () => {
      try {
        if (!activePlan) {
          await dispatch(syncAccountPlans());
        }
      } catch (error) {
        console.error("Failed to sync account plans:", error);
      } finally {
        setIsLoading(false);
      }
    };
    void fetchAccountPlans();
  }, [activePlan]);

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (!activePlan) {
    return <Navigate to="/admin/smartrr-account" />;
  }

  if (isSuperUser) {
    return <Navigate to="/admin/home" />;
  }

  return <Navigate to="/admin/subscriptions" />;
}
